import React from 'react';

import * as rawIcons from '@/components/icons';

const example = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
      {Object.values({ ...rawIcons }).map((Icon, index) => (
        <div key={index} className="flex items-center text-center">
          <div className="mr-2">
            <Icon />
          </div>
          <div>{Icon.displayName || Icon.name}</div>
        </div>
      ))}
    </div>
  );
};

export default example;
